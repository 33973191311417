import HomeLayout from "src/Vistas/LayOuts/HomeLayout.vue";
import VistasAuthLayout from "src/Vistas/LayOuts/AuthLayout.vue";
// GeneralViews
// import PaginaNoEncontrada from "src/Vistas/Main/PaginaNoEncontrada.vue";

let authVistas = {
  path: "/login2",
  component: VistasAuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Inicio de sesión",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/InicioSesion.vue"
        ),
    },
    {
      path: "/id_token*",
      name: "id_token",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/State.vue"
        ),
    },
    {
      path: "/error*",
      name: "error",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/Presenta.vue"
        ),
    },
    {
      path: "/state*",
      name: "state",
      component: () =>
        import(
          /* webpackChunkName: "usuarios" */ "src/Vistas/Usuarios/State.vue"
        ),
    },
    {
      path: "/Suscripciones/:SuscripcionID",
      name: "SuscripcionID",
      component: () =>
    
      import(/* webpackChunkName: "default" */ "src/pages/Suscripciones/index.vue"),

    },
  ]
}
let vistas = {
  path: "/layOut/empleado",
  component: HomeLayout,
  name: "AutoServicioMain",
  children: [

    
  ]};
// GeneralViews
const routes = [
  authVistas,vistas,
  {
    path: "/",
    name: "Cargando",
    component: () =>
      import(/* webpackChunkName: "main" */ "src/Vistas/Main/Home.vue"),
    meta: { requiresAuth: false },
  },


  {
    path: "/home",
    name: "Home",
    component: () =>
    import(/* webpackChunkName: "default" */ "src/pages/SL2C/index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/suscripciones/:SuscripcionID/home",
    name: "Home",
    component: () =>
    import(/* webpackChunkName: "default" */ "src/pages/SL2C/index.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/suscripciones/:SuscripcionID/toku",
    name: "Toku",
    component: () => 
    import(/* webpackChunkName: "default" */ "src/pages/SL2C/toku.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/suscripciones/:SuscripcionID/pagos",
    name: "Pagos",
    component: () => 
    import(/* webpackChunkName: "default" */ "src/pages/SL2C/pagos.vue"),
    meta: { requiresAuth: true },
  },

  // {
  //   path: "/EditarTipoArticulo/:id",
  //   name:"TipoArticulo",
  //   component: () =>
  //   import(/* webpackChunkName: "default" */ "src/pages/TipoArticulo/index.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path:"/Articulos/:TipoID",
  //   name:"Articulos",
  //   component: () => 
  //   import(/* webpackChunkName: "default" */ "src/pages/Articulos/index.vue"),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path:"/Suscripciones/:SuscripcionID/Articulos/:ArticuloID",
  //   name:"Articulo",
  //   component: () => 
  //   import(/* webpackChunkName: "default" */ "src/pages/Articulos/info.vue"),
  //   meta: { requiresAuth: true },
  // },

  // {
  //   path:"/Suscripciones/:SuscripcionID/Colaboradores/:ColaboradorID",
  //   name:"Colaborador",
  //   component: () => 
  //   import(/* webpackChunkName: "default" */ "src/pages/Articulos/Colaborador.vue"),
  //   meta: { requiresAuth: true },
  // },
  
  // {
  //   path: "/",
  //   name: "Default",
  //   component: () =>
  //     import(/* webpackChunkName: "default" */ "src/Vistas/Main/Default.vue"),
  // },

  // {
  //   path: "/r/:CodigoUnico",
  //   name: "Codigo",
  //   component: () =>
  //     import(/* webpackChunkName: "default" */ "src/pages/Cartas/_CodigoUnico"),
  // },

  // authVistas,
  // Agregar código para mostrar vacio
  // { path: "*", name: "Página no encontrada", component: PaginaNoEncontrada },
];

export default routes;
